<template>
  <div class="card">
    <div class="card-header">
      <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
      <el-dropdown @command="handleChange">
        <span class="el-dropdown-link">
          筛选<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="0">全部用户</el-dropdown-item>
          <el-dropdown-item :command="1">首次下单用户</el-dropdown-item>
          <el-dropdown-item :command="2">非首次下单用户</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="card-content">
      <el-table :data="showData" style="width: 100%">
        <el-table-column prop="memberTypeName" label="客户类型">
          <template slot-scope="scope">
            <span v-if="scope.row.memberTypeName === 'USER'">普通用户</span>
            <span v-if="scope.row.memberTypeName === 'MEMBER'">合伙人</span>
            <span v-if="scope.row.memberTypeName === 'VIP'">金牌合伙人</span>
          </template>
        </el-table-column>
        <el-table-column prop="userCount" label="用户数量"> </el-table-column>
        <el-table-column prop="ratio" label="用户数占比"> </el-table-column>
        <el-table-column label="客单价">
          <template slot-scope="scope">
            ￥{{ _pennyToYuan(scope.row.price) }}
          </template>
        </el-table-column>
        <el-table-column label="订单金额">
          <template slot-scope="scope">
            ￥{{ _pennyToYuan(scope.row.orderAmount) }}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as userStatistics from '@/api/userStatistics'
import * as R from 'ramda'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      defaultValue: 30,
      data: [],
      total: 0,
      pageSize: 8,
      currentPage: 1,
      orderType: 0
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    showData () {
      return R.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize,
        this.data
      )
    }
  },
  methods: {
    async fetchData (date = 30) {
      const query = getDateRange(date)
      query.orderType = this.orderType
      const res = await userStatistics.queryByType(query)
      this.handleData(res)
    },
    handleChange (val) {
      this.orderType = val
      this.fetchData(this.defaultValue)
    },
    onTabChange (val) {
      this.fetchData(val)
      this.defaultValue = val
    },
    handleSizeChange (val) {
      this.pageSize = val
    },
    handleCurrentChange (val) {
      this.currentPage = val
    },
    async onDateChange (data) {
      const query = fmtDataRange(data)
      query.orderType = this.orderType
      const res = await userStatistics.queryByType(query)
      this.handleData(res)
      this.total = res.length
    },
    handleData (data) {
      this.data = data
      this.total = data.length
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: #ffffff;
  height: 550px;
  margin: 20px auto;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d9e0f0;

    span {
      color: #999999;
      margin-right: 20px;
    }
  }

  &-content {
    padding: 20px 30px;
  }

  &-chart {
    &-title {
      margin-top: -20px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7d7e8e;
      line-height: 25px;
    }
    border-right: 1px solid #d9e0f0;
  }

  &-table {
    max-height: 440px;
    padding: 20px;
    overflow: auto;
  }
}
</style>
