<template>
  <div class="card">
    <div class="card-header">
      <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
      <div style="display: flex; margin-right: 20px;">
        <div class="tooltip">
          <img src="../../../assets/image/yellow.png" alt=".." />
          <span>访问数</span>
        </div>
        <div class="tooltip">
          <img src="../../../assets/image/green.png" alt=".." />
          <span>新增用户</span>
        </div>
        <div class="tooltip">
          <img src="../../../assets/image/red.png" alt=".." />
          <span>下单用户</span>
        </div>
        <div class="tooltip" style="margin-right: 0;">
          <img src="../../../assets/image/blue.png" alt=".." />
          <span>首次下单用户</span>
        </div>
      </div>
    </div>
    <div class="card-content">
      <ve-line :data="chartData" :extend="extend" :colors="colors"></ve-line>
    </div>
  </div>
</template>

<script>
import * as userStatistics from '@/api/userStatistics'
// import * as R from 'ramda'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

const map = {
  date: '日期',
  visitCount: '访问数',
  userCount: '新增用户',
  orderCount: '下单用户',
  firstOrderCount: '首次下单用户'
}

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      chartData: {
        columns: Object.values(map),
        rows: []
      },
      colors: ['#FEA239', '#50D166', '#F13A30', '#3270FF'],
      extend: {
        legend: {
          show: false
        }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData (date = 30) {
      const res = await userStatistics.queryByDay(getDateRange(date))
      this.handleData(res)
    },
    onTabChange (val) {
      this.fetchData(val)
    },
    async onDateChange (data) {
      const res = await userStatistics.queryByDay(fmtDataRange(data))
      this.handleData(res)
    },
    handleData (data) {
      this.chartData.rows = data.map(o => {
        const item = Object.keys(map).reduce((a, c) => {
          a[map[c]] = o[c]
          return a
        }, {})
        return item
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: #FFFFFF;
  height: 550px;
  margin: 20px auto;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #D9E0F0;

    span {
      color: #999999;
      /* margin-right: 20px; */
    }
  }

  &-content {
    width: 100%;
    /* display: grid; */
    /* grid-template-columns: 3fr 3fr 4fr; */
  }

  &-chart {
    &-title {
      margin-top: -20px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7D7E8E;
      line-height: 25px;
    }
    border-right: 1px solid #D9E0F0;
  }

  &-table {
    max-height: 440px;
    padding: 20px;
    overflow: auto;
  }
}

.tooltip {
  display: flex;
  align-items: center;
  margin-right: 20px;
  span {
    margin-left: 5px;
  }
}

</style>
